import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Loading from './components/loading';
import GA from './utils/GoogleAnalytics'

const Appp = React.lazy(() => import('./containers/pages/app/app'));
const AboutUs = React.lazy(() => import('./containers/pages/aboutUs/aboutUs'));
const TestPrep = React.lazy(() => import('./containers/pages/testPrep/testPrep'));
const Business = React.lazy(() => import('./containers/pages/business/ecampusBusiness'));
const Demo = React.lazy(() => import('./containers/pages/business/requestDemo'));
const Support = React.lazy(() => import('./containers/pages/support/home'));
const CourseDetails = React.lazy(() => import('./containers/pages/app/courseDetails'));
const RequestDeck = React.lazy(() => import('./containers/pages/aboutUs/contents/requestDeck'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        { GA.init() && <GA.RouteTracker /> }
        <React.Suspense fallback={Loading()}>
          <Switch>
            <Route path="/course/:id" exact name="Course Details" component={CourseDetails}/>
            <Route path="/about-us/request-deck" name="Company" component={RequestDeck}/>
            <Route path="/about-us" name="About Us" component={AboutUs}/>
            <Route path="/student" exact name="Test Prep" component={TestPrep}/>
            <Route path="/business" name="Business" component={Business}/>
            <Route path="/request-demo" name="Demo" component={Demo}/>
            {/*<Route path="/support" name="Support" component={Support}/>*/}
            <Route path="/" name="Home" component={Appp}/>
            <Redirect to='/'/>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }

}

export default App;
