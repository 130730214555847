import en from './lang/en.json'
import fr from './lang/fr.json'
const langData = {
    en,
    fr,
}

export const getLang = () =>{
    const lang = localStorage.getItem('ec-lang') ?? navigator.language
    if (/^en\b/.test(lang)) {
        return 'en'
    }
    else if (/^fr\b/.test(lang)) {
        return 'fr'
    }
    return 'en'
}

const content = () => {
    const lang = getLang()
    return langData[lang]
}

export default content



/*
* {
        'eCampusForStudents': 'eCampus for Students',
        'learningJustGotEasier': 'Learning just got easier. Set targets, take prep tests anytime and improve. No restrictions. Level up!',
        'startLearning': 'Start Learning',
        'becomeATeacher': 'Become a Teacher',
        'impartKnowledgeWhileEarningExtraIncome': 'Impart knowledge while earning extra income. Help refine knowledge for students all over Africa.',
        'startTeaching': 'Start Teaching',
        'eCampusForBusiness': 'eCampus for Business',
        'coachAndTrainYourEmployeesWithoutTheExtraHustle': 'Coach and train your employees without the extra hustle. Understand their strength areas in your business.',
        'requestADemo': 'Request a Demo',
        'joinCompaniesUsing': `Join companies using <span style="color: red">eCampus</span> for Business to train employees.`,
        'testimonies': 'Testimonies',
        'managingDirectorLetshegoGhana': 'Managing Director, Letshego Ghana',
        'itsAboutImprovingEmployeeProductivity': "It's about improving employee productivity. eCampus got it right with on-demand micro learning",
        'student': 'Student',
        'whenIUseECampusIEarnPoints': 'When I use eCampus, I earn points. I am happy to have the highest points in JHS Maths for the month of November.',
        'departmentalCounsellor': 'Departmental Counsellor, Presec Legon',
        'theECampusAppHasComeToLessenTheBurdenOfTeachers': 'The eCampus app has come to lessen the burden of teachers, because if am going to have a student who will learn and use this software to go for feedback to see his weakness and strength, I think it\'s a plus.',
        'strengthInNumbers': 'Strength in numbers',
        'meetYourLearningAndTrainingGoalsWithNoRestrictions': 'Meet your learning and training goals with no restrictions.',
        'startHere': 'Start Here',
        'students':'Students',
        'teachers':'Teachers',
        'business':'Business',
        'login':'Login',
        'signUp':'Sign Up',
        'courses':'Courses',
        'users':'Users',
        'classes':'Classes',
        'questions':'Questions',
        'learnOnTheGo':'Learn On The Go',
        'meetYourLearningAndTrainingGoals':'Meet your learning and training goals.',
        'appleStore':'Apple Store',
        'playStore':'Play Store',
        'eCampusForTeachers':'eCampus for Teachers',
        'eCampusPay':'eCampus Pay',
        'job':'Job',
        'aboutUs':'About Us',
        'support':'Support',
        'contactUs':'Contact Us',
        'termsAndConditions':'Terms and Conditions',
        'privacyPolicy':'Privacy Policy',
        'copyright':'Copyright',
        'home':'Home',
        'loading':'Loading',
        'thanksForReachingUs':'Thanks for reaching us!',
        'getInTouch':'Get in touch',
        'call':'Call',
        'usOn':'us on',
        'name':'Name',
        'email':'Email',
        'message':'Message',
        'submit':'Submit',
        'testPrep':'Test Prep',
        'prime':'Prime',
        'fellow':'Fellow',
        'book':'Book',
        'other':'Other',
        'yourPrivacyIsImportantToECampusLLC':'Your privacy is important to eCampus LLC. We have developed this Privacy Policy to set out how we collect, use, disclose, transfer, and store your information. This Privacy Policy applies to all Services provided by eCampus LLC. Please take a moment to read this Privacy Policy.',
        'informationCollected':'Information Collected',
        'ifYouRequestOrSubmitInformation':'If you request or submit information to us by sending an Email via the "mail to:" function or filling out a "contact" form, we may save your Email address as well as any other information you may provide. This information may be used to contact you in the future by mail, Email, or phone to convey information about our solutions or services that we feel may benefit you. Your Email and other information you provide will not be sold to any third party.',
        'informationCollectedByOthers':'Information Collected by Others',
        'thisNoticeAddressesOnlyThePolicyOfECampusLLC':'This notice addresses only the policy of eCampus LLC \'s website/app and not sites that users access via links from our site/app. eCampus LLC is not responsible for the information collection policies of other sites, nor for the practices employed by websites linked to or from our website/app, nor for the information or content contained therein. Often links to other websites are provided solely as pointers to information on topics that may be useful to our visitors. Users are advised to review the privacy policy of other websites.',
        'cookies':'Cookies',
        'eCampusLLCWebsiteAppDoesNotUseCookies':'eCampus LLC website/app does not use cookies. Updating, Correcting, and Deleting Personal Information If you would like to have your personal information removed from our records, please send an Email with "Remove personal information" in the subject line.',
        'legallyCompelledDisclosureOfInformation':'Legally Compelled Disclosure of Information',
        'eCampusLLCMayDiscloseInformation':'eCampus LLC may disclose information when legally compelled to do so; in other words, when we, in good faith, believe that the law requires it or for the protection of our legal rights.',
        'periodicPolicyChanges':'Periodic Policy Changes',
        'pleaseNoteThatECampusLLCReviews':'Please note that eCampus LLC reviews its privacy practices from time-to-time (i.e. to track technology and/or legal changes), and that these practices are subject to change. To ensure continuing familiarity with the most current version of our privacy policy, please bookmark and periodically review this page.',
        'thisPolicyStatementIsMade':'This policy statement is made in the name of eCampus LLC. This statement does not create an agreement between eCampus LLC and users, and as such, does not create any legal rights for any party.',
        'website':'Website',
        'back':'Back',
        'backToApp':'Back to App',
        'frequentlyAskedQuestions':'Frequently Asked Questions',
        'howToContactYourCourseInstructor':'How to Contact Your Course Instructor',
        'iCantFindMyCourse':"I Can't Find my Course",
        'videoAudioIssuesTroubleshooting':'Video & Audio Issues: Troubleshooting',
        'purchaseIssues':'Purchase Issues',
        'teachingCenter':'Teaching Center',
        'visitTheTeachingCenter':'Visit the Teaching Center for community updates and the latest course creation and promotion resources.',
        'takeMeThere':'Take Me There',
        'becomeAPremiumInstructorAndChargeForYourCourse':'Become a Premium Instructor and Charge for Your Course',
        'instructorRevenueShare':'Instructor Revenue Share',
        'openASupportTicket':'Open a support ticket',
        'howMayWeHelpYou':'How may we help you?',
        'instructor':'Instructor',
        'yourUseOfECampusLLCsApp':'Your use of eCampus LLC’s app (the “App”) is expressly conditioned on your acceptance of the following terms and conditions. By using the App, you signify your assent to these terms and conditions. If you do not agree with any part of the following terms and conditions, you must not use the App. By using this App, you agree to be legally bound by all the terms and conditions contained herein. When you use any current or future version of this App you also will be subject to the terms and conditions of this Terms of Use.',
        'weMayFromTimeToTime':'We may, from time to time, modify the terms of this Agreement. When we make changes, we will post those changes here. Your use of the App following any such modification constitutes your agreement to the terms of the modified Agreement. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.',
        'ownership':'OWNERSHIP',
        'exceptForItemsInThePublicDomain':'Except for items in the public domain, the information, photos, graphics, programs, services, processes, designs, software, technologies, trademarks, trade names, inventions and all other materials comprising the App (collectively, the "Materials"), are wholly owned by (or licensed to) eCampus LLC and/or its content providers. The App contains copyrighted material, trademarks, and other proprietary information including, without limitation, text, software, photos, video, graphics, music and sound, and the entire contents of the App is copyrighted under the Ghana copyright laws. eCampus LLC owns a copyright in the selection, coordination, arrangement, and enhancement of such content as well as in any original content. Nothing contained on this app should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the Materials without the written permission of eCampus LLC or such other party that may own the Materials.',
        'useOfApp':'USE OF APP',
        'exceptForYourNonCommercialPersonalUse':'Except for your non-commercial personal use, the App, in whole or in part, may not be sold, reproduced, published, broadcasted via radio or television, or redistributed in any medium, directly or indirectly, for any commercial or non-commercial purpose, or used for advertising or any other purpose other. Unauthorized use of the App and/or the Materials may violate applicable copyright, trademark or other intellectual property laws or other laws. You must retain all copyright and trademark notices, including any other proprietary notices, contained in the materials. The use of such materials on any other app or in any environment of networked computers is prohibited. You are prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, indecent, inflammatory, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law.',
        'ageAndResponsibility':'AGE AND RESPONSIBILITY',
        'youRepresentThatYouAreOfSufficient':'You represent that you are of sufficient legal age to use the App and to create binding legal obligations for any liability you may incur as a result of the use of the App. You understand that you are financially responsible for all uses of the App by you and those using your login information.',
        'privacy':'PRIVACY',
        'youHaveReadTheECampusLLCPrivacyPolicy':'You have read the eCampus LLC Privacy Policy, the terms of which are incorporated herein, and agree that the terms of such policy are reasonable. You consent to the use of your personal information by eCampus LLC and/or its third party providers and distributors in accordance with the terms of and for the purposes set forth in the eCampus LLC Privacy Policy.',
        'noWarrantiesAndLimitationOfLiability':'NO WARRANTIES AND LIMITATION OF LIABILITY',
        'yourUseOfTheAppIsAtYourSole':'Your use of the App is at your sole risk. You assume full responsibility and risk of loss resulting from the downloading and/or use of files or other material (including software) obtained through the App. Neither eCampus LLC nor any of its content providers endorse or stand behind the accuracy, truthfulness or reliability of any information provided on or by means of eCampus LLC.',
        'theAppIsProvidedAsIs':'THE APP IS PROVIDED "AS IS", AND NEITHER eCampus LLC NOR ANY OF ITS SUPPLIERS, CONTENT PROVIDERS, LICENSORS, EMPLOYEES, AGENTS, OR THE LIKE MAKES ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING THE USABILITY, CONDITION OR OPERATION THEREOF. No oral advice or written information given to you by eCampus LLC or any of its content providers, agents, or the like shall create any warranty. Neither eCampus LLC nor any of its content providers, agents, or the like warrants that access to, or use of, the App will be uninterrupted, virus free, or error-free, or that the App (including, without limitation, any content on the App) will meet any particular criteria of performance or quality. eCampus LLC, ON BEHALF OF ITSELF AND ALL OF ITS CONTENT PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND/OR REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN, INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, REASONABLE CARE, COMPATIBILITY, SECURITY, RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT eCampus LLC KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), IN EACH INSTANCE IN RESPECT OF THE APP. FURTHERMORE, eCampus LLC, ON BEHALF OF ITSELF AND ALL OF ITS CONTENT PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT IN RESPECT OF THE APP.',
        'limitationOfLiability':'LIMITATION OF LIABILITY',
        'indemnification':'INDEMNIFICATION',
        'youAgreeToIndemnifyECampusLLCAndAllOfItsContent':'You agree to indemnify eCampus LLC and all of its content providers, agents and the like, in each instance from and against any and all claims, suits, liabilities, expenses (including, without limitation, attorneys\' fees), and/or damages arising out of claims based upon use your use of the App including, without limitation, any claim of libel, defamation, violation of legal rights of others (including rights of privacy or publicity), loss of service by other subscribers and/or infringement of intellectual property or other rights.',
        'links':'LINKS',
        'theAppMayContainLinksToWebsites':'The App may contain links to websites operated by parties other than eCampus LLC. Such links are provided for your reference only. eCampus LLC does not control such websites and is not responsible for their contents. eCampus LLC makes no representation or warranty regarding any other websites or the contents or materials on such websites. The App’s inclusion of links to other websites does not imply eCampus LLC \'s endorsement of the material on the websites or any association with their operators. Any concerns regarding any such service or resource, or any link thereto, should be directed to the particular service or resource',
        'governingLaw':'GOVERNING LAW',
        'thisAgreementAndItsPerformance':'This Agreement and its performance shall be governed by the laws of Ghana, without regard to its conflict of law provisions. You consent and submit to the exclusive jurisdiction of Ghana courts, in all questions and controversies arising out of your use of this app and this Agreement.',
        'noWaiver':'NO WAIVER',
        'theFailureOfEitherPartyToInsistUpon':'The failure of either party to insist upon or enforce strict performance by the other party of any provision of these Terms and Conditions shall not be construed as a waiver of any provision or right. Neither the course of conduct between you and eCampus LLC nor the trade practice shall modify any provision of these Terms and Conditions.',
        'modification':'MODIFICATION',
        'eCampusLLCMayAtAnytimeModify':'eCampus LLC may at any time modify these terms and conditions and your continued use of this app will be conditioned upon the terms and conditions in force at the time of your use.',
        'additionalTerms':'ADDITIONAL TERMS',
        'additionalTermsAndConditions':'Additional terms and conditions may apply to purchases of goods and services and other uses of portions of the App, and you agree to abide by such other terms and conditions.',
        'severability':'SEVERABILITY',
        'theseTermsAndConditionsShall':'These terms and conditions shall be deemed severable. In the event that any provision is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions.',
        'entireAgreement':'ENTIRE AGREEMENT',
        'theseTermsAndConditions':'These terms and conditions, together with those incorporated herein or referred to herein constitute the entire agreement between us relating to the subject matter hereof, and supersedes any prior understandings or agreements (whether oral or written) regarding the subject matter, and may not be amended or modified except in writing or by making such amendments or modifications available on this App.',
        'eCampusAppEndUserLicenseAgreement':'eCampus App End User License Agreement',
        'thisEndUserLicenseAgreement':'This End User License Agreement (“Agreement”) is between you and eCampus and governs use of this app made available through the App Store. By installing the eCampus App, you agree to be bound by this Agreement and understand that there is no tolerance for objectionable content. If you do not agree with the terms and conditions of this Agreement, you are not entitled to use the eCampus App.',
        'inOrderToEnsureECampusProvides':'In order to ensure eCampus provides the best experience possible for everyone, we strongly enforce a no tolerance policy for objectionable content. If you see inappropriate content, please use the "Report as offensive" feature found under each post.',
        'partiesThisAgreementIsBetween':'Parties This Agreement is between you and eCampus only. Notwithstanding the foregoing, you acknowledge that App store and its subsidiaries are third party beneficiaries of this Agreement and App store has the right to enforce this Agreement against you. eCampus, not App store, is solely responsible for the eCampus App and its content.',
        'privacyECampusMayCollect':'Privacy eCampus may collect and use information about your usage of the eCampus App, including certain types of information from and about your device. eCampus may use this information, as long as it is in a form that does not personally identify you, to measure the use and performance of the eCampus App.',
        'limitedLicenseECampusGrants':'Limited License eCampus grants you a limited, non-exclusive, non-transferable, revocable license to use eCampus App for your personal, non-commercial purposes. You may only use the eCampus App on devices that you own or control and as permitted by the App Store Terms of Service.',
        'ageRestrictionsByUsingTheECampusApp':'Age Restrictions By using the eCampus App, you represent and warrant that (a) you are 17 years of age or older and you agree to be bound by this Agreement; (b) if you are under 17 years of age, you have obtained verifiable consent from a parent or legal guardian; and (c) your use of the eCampus App does not violate any applicable law or regulation. Your access to the eCampus App may be terminated without warning if eCampus believes, in its sole discretion, that you are under the age of 17 years and have not obtained verifiable consent from a parent or legal guardian. If you are a parent or legal guardian and you provide your consent to your child\'s use of the eCampus App, you agree to be bound by this Agreement in respect to your child\'s use of the eCampus App.',
        'objectionableContentPolicyContent':'Objectionable Content Policy Content may not be submitted to eCampus, who will moderate all content and ultimately decide whether or not to post a submission to the extent such content includes, is in conjunction with, or alongside any, Objectionable Content. Objectionable Content includes, but is not limited to: (i) sexually explicit materials; (ii) obscene, defamatory, libelous, slanderous, violent and/or unlawful content or profanity; (iii) content that infringes upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary right, or that is deceptive or fraudulent; (iv) content that promotes the use or sale of illegal or regulated substances, tobacco products, ammunition and/or firearms; and (v) gambling, including without limitation, any online casino, sports books, bingo or poker.',
        'warrantyECampusDisclaimsAll':'Warranty eCampus disclaims all warranties about the eCampus App to the fullest extent permitted by law. To the extent any warranty exists under law that cannot be disclaimed, eCampus, shall be solely responsible for such warranty.',
        'maintenanceAndSupportECampus':'Maintenance and Support eCampus does provide minimal maintenance or support for it but not to the extent that any maintenance or support is required by applicable law, eCampus, shall be obligated to furnish any such maintenance or support.',
        'productClaimsECampus':'Product Claims eCampus is responsible for addressing any claims by you relating to the eCampus App or use of it, including, but not limited to: (i) any product liability claim; (ii) any claim that the eCampus App fails to conform to any applicable legal or regulatory requirement; and (iii) any claim arising under consumer protection or similar legislation. Nothing in this Agreement shall be deemed an admission that you may have such claims.',
        'thirdPartyIntellectualProperty':'Third Party Intellectual Property Claims eCampus shall not be obligated to indemnify or defend you with respect to any third party claim arising out or relating to the eCampus App. To the extent eCampus is required to provide indemnification by applicable law, eCampus, shall be solely responsible for the investigation, defense, settlement and discharge of any claim that the eCampus App or your use of it infringes any third party intellectual property right. Agree Decline',
        'toGetStartedAsATeacher':'To get started as a teacher on eCampus, sign up with your details.',
        'fullNameEmail':`<p>Full Name <br/> Email <br/> Contact <br/>Bank Name and Account Number <br/>Lesson Areas and Skills</p>`,
        'selectYourCourse':'Select your course and organise your content into videos, animations or text with images. Create quizzes to help students better understand what you are teaching.',
        'howECampusHelpsYou':'How eCampus helps you',
        'ourSupportTeamIsReadily':'Our support team is readily available to assist you to improve your offering to students. Alternatively, you can track your dashboard to understand which courses and content students interact with more.',
        'byConstantlyCreatingInteractive':'By constantly creating interactive content and being available to offer further clarification to students you rank higher and transform more lives.',
        'weProvideAllTheTools':'We provide all the tools you need to build a large following.',
        'signUpToBeATeacher':'Sign up to be a teacher',
        'planAndCreateYourContent':'Plan and create your content',
        'growAFollowing':'Grow a following',
        'earnMoney':'Earn money',
        'withEachPurchase':'With each purchase of your course material, you earn more. Make your course content interactive and fun and make more money.',
        'howECampusPaymentAreMade':'How eCampus payment are made',
        'eachMonthPayment':'Each month payment are made through bank directly to eCampus Teachers',
        'contactHours':'Contact hours',
        'impartAndEarn':'Impart and Earn',
        'teachStudentsAll':'Teach students all over Africa with your content and earn money.',
        'loginToDashboard':'Login to Dashboard',
        'earnExtraMoney':'Earn extra money',
        'getPaidEachTime':'Get paid each time a student interacts with your course. Earn more while transforming a life with your skills.',
        'teachToTransform':'Teach to Transform',
        'beTheReasonAStudent':'Be the reason a student becomes better at a course or subject by sharing your knowledge. Transform many lives with your content.',
        'becomeATeamMember':'Become a Team Member',
        'joinOurCommunityOfTeachers':'Join our community of teachers, become better at teaching and earn more.',
        'joinTheTeam':'Join the team',
        'transformAStudent':'Transform a student',
        'becomeAnECampusTeacherTodayTeachToTransform':'Become an eCampus teacher today, Teach to transform.',
        'improveYourBusinessWithSkilledEmployees':'Improve your Business with skilled employees',
        'identifyHiddenTalents':'Identify hidden talents in your business today. Using a combination of Digital, data and content expertise we have crafted courses which will help your employees succeed and improve general efficiency and effectiveness at work.',
        'theWorkDoneByECampus':'"The work done by eCampus is unique with emphasis on education which has been our focus this year."',
        'marketingBusinessDevelopmentManager':`Mrs Jennifer Bebli <br/> Marketing & Business Development Manager <br/> Donewell Insurance, Ghana`,
        'recognizeTheStrengthsOfYourEmployees':'Recognize the strengths of your Employees',
        'identifyTheStrengthsAndWeaknesses':'Identify the strengths and weaknesses of your employees. Coach them to become better and improve efficiency in the work place.',
        'content':'Content',
        'pricing':'Pricing',
        'requestDemo':'Request Demo',
        'demo':'Demo',
        'improveTheSkillsOfYourEmployees':'Improve the skills of your employees.',
        'giveYourEmployeesAnEdgeToSucceed':'Give your employees an edge to succeed.',
        'learnSmart':'Learn Smart',
        'cancel':'Cancel',
        'coachYourEmployees':'Coach your employees to become better and improve work efficiency in the work place.',
        'firstName':'First Name',
        'lastName':'Last Name',
        'companyName':'Company Name',
        'jobTitle':'Job Title',
        'contact':'Contact',
        'training':'Training',
        'idLikeToReceiveNews':"I’d like to receive news, insights, and promotions from eCampus for Business",
        'bySigningUpYouAgreeToOur':'By signing up, you agree to our',
        'termsOfUse':'Terms of Use',
        'and':'and',
        'weMayUseTheInformation':'We may use the information you submit to contact you and use data from third parties to personalize your experience.',
        'bePreparedAlways':'Be prepared always',
        'passAnyTestOrExamYouTake':'Pass any test or exam you take.',
        'takeATrial':'Take a Trial',
        'toGetStartedOnECampusSignUpWithYourDetails':'To get started on eCampus, sign up with your details.',
        'fullName':'Full Name',
        'moreThanAHundredCourses':'More than a hundred courses are available at affordable prices to practice and become perfect. Select the course or courses you want to study.',
        'reachOutToOur':'Reach out to our support team if you need clarity on courses you will like to purchase.',
        'learn':'Learn',
        'toBecomeBetterAndMeet':'To become better and meet your learning targets, you need to learn. After purchasing a course don\'t relax on your ores, study from all resource materials and questions available.',
        'takePrepTest':'Take Prep test',
        'nowThatYouHaveStudied':'Now that you have studied, take many prep tests to understand your strengths and weaknesses. Become better in your weak areas and improve your areas of strength.',
        'repeat':'Repeat',
        'yourUltimateGoal':'Your ultimate goal is to be the best! To achieve that,you ought to keep practicing. Repeat the whole cycle and become a better student. Reach your learning goals with determination.',
        'trendingNow':'Trending Now',
        'studyingForAnExam':'Studying for an exam shouldn’t be so hard. Don’t get caught up in the monopoly and boredom of the educational system. Study and improve yourself anytime, anyday and anywhere. With eCampus learn without restrictions.',
        'payForASelectedCourse':'Pay for a selected course(s)',
        'learnEarnPoints':'Learn & earn points',
        'takePrepTestEarnPoints':'Take prep test & earn points',
        'signUpForMore':'Sign Up for more',
        'areYouReady':'Are you ready',
        'forYourUpComingExamination':'for your up coming examination?',
        'checkHere':'Check Here',
        'getStarted':'Get Started',
        'doWhateverYouWantWhenYouWantTo':'Do whatever you want when you want to.',
        'imagineACompany':'Imagine-a-Company',
        'work':'Work',
        'company':'Company',
        'careers':'Careers',
        'weHelpCompaniesAndCompanions':'We Help Companies and Companions',
        'weCanGiveYourCompany':'We can give your company superpowers to do things that they never thought possible. Let us delight your customers and empower your needs... through pure data analytics.',
        'weMakeBananasThatCanDance':'We Make Bananas That Can Dance',
        'yesThatsRight':"Yes that's right, you thought it was the stuff of dreams, but even bananas can be\n" +
            "              bioengineered.",
        'checkThemOut':'Check Them Out',
        'whatACompany':'"What a Company"',
        'thatIsWhatTheyAllSayAboutUs':'That is what they all say about us',
        'iShouldntHaveGoneWithTheirCompetitor':'"I shouldn\'t have gone with their competitor."',
        'bringingDreamsToLife':'Bringing dreams to life',
        'investors':'Investors',
        'blog':'Blog',
        'press':'Press',
        'linkedIn':'LinkedIn',
        'eCampusIsAWebAndMobile':"eCampus is a web and mobile based online teaching and learning (EdTech) platform that uses artificial intelligence to help learners learn smarter, prepare learners for employment and guide learners towards prosperity. #LearnSmarter #Employability #Prosperity @eCampusLLC",
        'eCampusIsAWeb':' eCampus is a web and mobile based online teaching and learning (EdTech) platform that uses artificial intelligence to help learners learn smarter, prepare learners for employment and guide learners towards prosperity.',
        'learnSmarter':`<p style="margin-left:20px">#<b>LearnSmarter</b> #<b>Employability</b> #<b>Prosperity</b> <b>@eCampusLLC</b></p>`,
        'ourPartners':'Our Partners',
        'areYouInterestedInInvestingInTheFutureOfEducation':'Are you Interested in investing in the future of education?',
        'kindlySendUsARequest':'Kindly send us a request for our pitch deck using this form below, thank you for your interest in eCampus.',
        'requestADeck':'Request a deck',
        'readMore':'Read more',
        'whatWeHearAboutUs':'What we hear about us',
        'investInTheFutureOfEducationToday':'Invest in the future of education today',
        'ourInvestors':'Our Investors',
        'ourTeam':'Our Team',
        'positivelyImpactingSocietyThroughLearning':'Positively impacting society through learning',
        'ourBoard':'Our Board',
        'ourAdvisors':'Our Advisors',
        'eCampusDeck':'eCampus Deck',
        'signUpToBeAStudent':'Sign up to be a student',
        'levelUp':'Level up',
        'startToday':'Start today',
        'subjectAreas':'Subject Areas',
        'useTheApp':'Use the App',
        'makeAnImpact':'Make an Impact',
        'trendingCoursesNow':'Trending Courses Now',
    }

*
* {
        'eCampusForStudents': 'eCampus pour les étudiants',
        'learningJustGotEasier': 'Apprendre devient plus facile. Fixez des objectifs, passez des tests de préparation à tout moment et améliorez vos résultats. Aucune restriction. Améliorez votre niveau !',
        'startLearning': 'Commencer à apprendre',
        'becomeATeacher': 'Devenir enseignant',
        'impartKnowledgeWhileEarningExtraIncome': 'Transmettez vos connaissances tout en gagnant un revenu supplémentaire. Aidez à affiner les connaissances des étudiants dans toute l\'Afrique.',
        'startTeaching': 'Commencer à enseigner',
        'eCampusForBusiness': 'eCampus pour les entreprises',
        'coachAndTrainYourEmployeesWithoutTheExtraHustle': 'Encadrez et formez vos employés sans vous fatiguer. Comprenez leurs points forts dans votre entreprise.',
        'requestADemo': 'Demander une démo',
        'joinCompaniesUsing': `Rejoignez les entreprises qui utilisent <span style="color : red">eCampus</span> for Business pour former leurs employés.`,
        'testimonies': 'Témoignages',
        'managingDirectorLetshegoGhana': 'Directeur général, Letshego Ghana',
        'itsAboutImprovingEmployeeProductivity': "Il s'agit d'améliorer la productivité des employés. eCampus l'a bien compris avec la micro-formation à la demande.",
        'student': 'Étudiant',
        'whenIUseECampusIEarnPoints': 'Quand j\'utilise eCampus, je gagne des points. Je suis heureux d\'avoir le plus de points en maths JHS pour le mois de novembre.',
        'departmentalCounsellor': 'Conseillère départementale, Presec Legon',
        'theECampusAppHasComeToLessenTheBurdenOfTeachers': "L'application eCampus est venue alléger le fardeau des enseignants, car si je vais avoir un étudiant qui va apprendre et utiliser ce logiciel pour aller chercher du feedback afin de voir ses faiblesses et ses forces, je pense que c'est un plus.",
        'strengthInNumbers': "L'union fait la force",
        'meetYourLearningAndTrainingGoalsWithNoRestrictions': 'Atteignez vos objectifs d\'apprentissage et de formation sans aucune restriction.',
        'startHere': 'Commencez ici',
        'students':'Étudiants',
        'teachers':'Enseignants',
        'business':'Entreprise',
        'login':'Connexion',
        'signUp':"S'inscrire",
        'courses':'Cours',
        'users':'Utilisateurs',
        'classes':'Classes',
        'questions':'Questions',
        'learnOnTheGo':'Apprendre sur le tas',
        'meetYourLearningAndTrainingGoals':'Atteindre vos objectifs d\'apprentissage et de formation.',
        'appleStore':'Apple Store',
        'playStore':'Play Store',
        'eCampusForTeachers':'eCampus pour les enseignants',
        'eCampusPay':'Paiement eCampus',
        'job':'Emploi',
        'aboutUs':'A propos de nous',
        'support':'Soutien',
        'contactUs':'Nous contacter',
        'termsAndConditions':'Modalités et conditions',
        'privacyPolicy':'Politique de confidentialité',
        'copyright':'Copyright',
        'home':'Accueil',
        'loading':'Chargement',
        'thanksForReachingUs':'Merci de nous joindre!',
        'getInTouch':'Prenez contact avec nous',
        'call':'Appelez',
        'usOn':'nous sur',
        'name':'Nom',
        'email':'Courriel',
        'message':'Message',
        'submit':'Soumettre',
        'testPrep':'Préparation aux tests',
        'prime':'Prime',
        'fellow':'Collègue',
        'book':'Livre',
        'other':'Autre',
        'yourPrivacyIsImportantToECampusLLC':'Votre vie privée est importante pour eCampus LLC. Nous avons élaboré cette politique de confidentialité pour définir la manière dont nous recueillons, utilisons, divulguons, transférons et conservons vos informations. Cette politique de confidentialité s\'applique à tous les services fournis par eCampus LLC. Veuillez prendre un moment pour lire cette politique de confidentialité.',
        'informationCollected':'Informations collectées',
        'ifYouRequestOrSubmitInformation':'Si vous demandez ou nous soumettez des informations en envoyant un courrier électronique via la fonction "mail to :" ou en remplissant un formulaire de "contact", nous pouvons enregistrer votre adresse électronique ainsi que toute autre information que vous pouvez fournir. Ces informations peuvent être utilisées pour vous contacter à l\'avenir par courrier, courriel ou téléphone afin de vous transmettre des informations sur nos solutions ou services qui, selon nous, pourraient vous être utiles. Votre adresse électronique et les autres informations que vous fournissez ne seront pas vendues à des tiers.',
        'informationCollectedByOthers':'Informations collectées par d\'autres',
        'thisNoticeAddressesOnlyThePolicyOfECampusLLC':'Cet avis ne concerne que la politique du site Web/application de eCampus LLC et non les sites auxquels les utilisateurs accèdent par des liens à partir de notre site/application. eCampus LLC n\'est pas responsable des politiques de collecte d\'information des autres sites, ni des pratiques employées par les sites Web liés à notre site Web/application, ni des informations ou du contenu qui s\'y trouvent. Souvent, les liens vers d\'autres sites web sont fournis uniquement comme pointeurs vers des informations sur des sujets qui peuvent être utiles à nos visiteurs. Il est conseillé aux utilisateurs de consulter la politique de confidentialité des autres sites web.',
        'cookies':'Cookies',
        'eCampusLLCWebsiteAppDoesNotUseCookies':'Le site/application eCampus LLC n\'utilise pas de cookies. Mise à jour, correction et suppression des informations personnelles Si vous souhaitez que vos informations personnelles soient supprimées de nos dossiers, veuillez envoyer un courriel avec la mention "Supprimer les informations personnelles" dans l\'objet.',
        'legallyCompelledDisclosureOfInformation':'Divulgation d\'informations légalement obligatoire',
        'eCampusLLCMayDiscloseInformation':'La société eCampus LLC peut divulguer des informations lorsqu\'elle y est légalement contrainte ; en d\'autres termes, lorsque nous pensons, en toute bonne foi, que la loi l\'exige ou pour la protection de nos droits légaux.',
        'periodicPolicyChanges':'Changements périodiques de politique',
        'pleaseNoteThatECampusLLCReviews':'Veuillez noter que la société eCampus LLC revoit ses pratiques en matière de confidentialité de temps à autre (c\'est-à-dire pour suivre les changements technologiques et/ou juridiques), et que ces pratiques sont susceptibles d\'être modifiées. Pour vous familiariser avec la version la plus récente de notre politique de confidentialité, veuillez marquer cette page d\'un signet et la consulter périodiquement.',
        'thisPolicyStatementIsMade':'Cette déclaration de politique générale est faite au nom de la société eCampus LLC. Cette déclaration ne crée pas d\'accord entre eCampus LLC et les utilisateurs, et en tant que tel, ne crée aucun droit légal pour aucune des parties.',
        'website':'Site web',
        'back':'Dos',
        'backToApp':'Retour à l\'application',
        'frequentlyAskedQuestions':'Questions fréquemment posées',
        'howToContactYourCourseInstructor':'Comment contacter l\'instructeur de votre cours',
        'iCantFindMyCourse':"Je ne trouve pas mon cours",
        'videoAudioIssuesTroubleshooting':'Problèmes vidéo et audio : Dépannage',
        'purchaseIssues':'Questions relatives aux achats',
        'teachingCenter':'Centre d\'enseignement',
        'visitTheTeachingCenter':'Visitez le centre d\'enseignement pour les mises à jour de la communauté et les dernières ressources de création et de promotion de cours.',
        'takeMeThere':'Emmène-moi là-bas',
        'becomeAPremiumInstructorAndChargeForYourCourse':'Devenez un instructeur Premium et faites payer votre cours',
        'instructorRevenueShare':'Part des revenus des instructeurs',
        'openASupportTicket':'Ouvrir un ticket d\'assistance',
        'howMayWeHelpYou':'Comment pouvons-nous vous aider ?',
        'instructor':'Instructeur',
        'yourUseOfECampusLLCsApp':'Votre utilisation de l\'application de eCampus LLC (l\'"Application") est expressément conditionnée par votre acceptation des termes et conditions suivants. En utilisant l\'application, vous signifiez votre consentement à ces termes et conditions. Si vous n\'êtes pas d\'accord avec une partie des termes et conditions suivants, vous ne devez pas utiliser l\'application. En utilisant cette application, vous acceptez d\'être légalement lié par toutes les conditions générales contenues dans le présent document. Lorsque vous utilisez une version actuelle ou future de cette application, vous êtes également soumis aux termes et conditions des présentes conditions d\'utilisation.',
        'weMayFromTimeToTime':'Nous pouvons, de temps à autre, modifier les termes de cet accord. Lorsque nous apporterons des modifications, nous publierons ces modifications ici. Votre utilisation de l\'application après une telle modification constitue votre accord avec les termes de l\'accord modifié. Nous nous réservons le droit de refuser le service, de fermer des comptes, de supprimer ou de modifier le contenu, ou d\'annuler des commandes à notre seule discrétion.',
        'ownership':'PROPRIÉTÉ PUBLIQUE',
        'exceptForItemsInThePublicDomain':'À l\'exception des éléments du domaine public, les informations, les photos, les graphiques, les programmes, les services, les processus, les conceptions, les logiciels, les technologies, les marques de commerce, les noms commerciaux, les inventions et tous les autres matériaux composant l\'application (collectivement, les "matériaux"), sont entièrement détenus par (ou sous licence) eCampus LLC et/ou ses fournisseurs de contenu. L\'application contient du matériel protégé par le droit d\'auteur, des marques de commerce et d\'autres renseignements exclusifs, y compris, sans s\'y limiter, du texte, des logiciels, des photos, des vidéos, des graphiques, de la musique et du son, et l\'ensemble du contenu de l\'application est protégé par le droit d\'auteur en vertu des lois sur le droit d\'auteur du Ghana. eCampus LLC détient un droit d\'auteur dans la sélection, la coordination, l\'arrangement et l\'amélioration de ce contenu ainsi que dans tout contenu original. Rien de ce qui est contenu dans cette application ne doit être interprété comme accordant, par implication, estoppel ou autre, une licence ou un droit d\'utilisation de tout matériel sans l\'autorisation écrite de eCampus LLC ou de toute autre partie qui peut être propriétaire du matériel.',
        'useOfApp':'UTILISATION DE L\'APP',
        'exceptForYourNonCommercialPersonalUse':'À l\'exception de votre utilisation personnelle non commerciale, l\'App, en tout ou en partie, ne peut être vendue, reproduite, publiée, diffusée à la radio ou à la télévision, ou redistribuée sur quelque support que ce soit, directement ou indirectement, à des fins commerciales ou non commerciales, ou utilisée à des fins publicitaires ou à toute autre fin. L\'utilisation non autorisée de l\'application et/ou du matériel peut constituer une violation des lois applicables en matière de droits d\'auteur, de marques ou de propriété intellectuelle ou d\'autres lois. Vous devez conserver tous les avis de droit d\'auteur et de marque, y compris tout autre avis de propriété, contenus dans les matériaux. L\'utilisation de ces matériaux sur toute autre App ou dans tout environnement d\'ordinateurs en réseau est interdite. Il vous est interdit d\'afficher ou de transmettre tout matériel illégal, menaçant, diffamatoire, obscène, indécent, incendiaire, pornographique ou blasphématoire ou tout matériel qui pourrait constituer ou encourager une conduite qui serait considérée comme une infraction criminelle, donner lieu à une responsabilité civile ou violer autrement toute loi.',
        'ageAndResponsibility':'ÂGE ET RESPONSABILITÉ',
        'youRepresentThatYouAreOfSufficient':'Vous déclarez que vous avez l\'âge légal suffisant pour utiliser l\'App et pour créer des obligations légales contraignantes pour toute responsabilité que vous pourriez encourir suite à l\'utilisation de l\'App. Vous comprenez que vous êtes financièrement responsable de toutes les utilisations de l\'application par vous-même et par ceux qui utilisent vos informations de connexion.',
        'privacy':'PRIVACY',
        'youHaveReadTheECampusLLCPrivacyPolicy':'Vous avez lu la Politique de confidentialité de eCampus LLC, dont les termes sont incorporés aux présentes, et vous convenez que les termes de cette politique sont raisonnables. Vous consentez à l\'utilisation de vos renseignements personnels par eCampus LLC et/ou ses fournisseurs et distributeurs tiers conformément aux conditions et aux fins énoncées dans la Politique de confidentialité de eCampus LLC.',
        'noWarrantiesAndLimitationOfLiability':'ABSENCE DE GARANTIES ET LIMITATION DE LA RESPONSABILITÉ',
        'yourUseOfTheAppIsAtYourSole':'Vous utilisez l\'application à vos propres risques. Vous assumez l\'entière responsabilité et le risque de perte résultant du téléchargement et/ou de l\'utilisation de fichiers ou d\'autres matériels (y compris les logiciels) obtenus par le biais de l\'Application. Ni eCampus LLC ni aucun de ses fournisseurs de contenu n\'endosse ou ne se porte garant de l\'exactitude, de la véracité ou de la fiabilité de toute information fournie sur ou par le biais de eCampus LLC.',
        'theAppIsProvidedAsIs':'L\'APP EST FOURNI " TEL QUEL ", ET NI eCampus LLC NI AUCUN DE SES FOURNISSEURS, FOURNISSEURS DE CONTENU, LICENCIEURS, EMPLOYÉS, AGENTS OU AUTRE N\'OFFRE AUCUNE REPRÉSENTATION OU GARANTIE EXPRESSE OU IMPLICITE CONCERNANT L\'USABILITÉ, LA CONDITION OU LE FONCTIONNEMENT DE L\'APP. Aucun conseil oral ou information écrite qui vous serait donné par eCampus LLC ou par l\'un de ses fournisseurs de contenu, agents ou autres ne saurait créer une quelconque garantie. Ni eCampus LLC ni aucun de ses fournisseurs de contenu, agents ou autres ne garantissent que l\'accès ou l\'utilisation de l\'Application sera ininterrompu, sans virus ou sans erreur, ou que l\'Application (y compris, sans limitation, tout contenu de l\'Application) répondra à tout critère particulier de performance ou de qualité. eCampus LLC, au nom de lui-même et de tous ses fournisseurs de contenu, agents et autres, renonce par la présente à toute garantie et/ou représentation, qu\'elle soit expresse ou implicite, orale ou écrite, y compris, mais sans s\'y limiter, toute garantie implicite de qualité marchande, de soins raisonnables, de compatibilité, de sécurité, de fiabilité, d\'exactitude et/ou d\'adéquation à un usage particulier (que eCampus LLC sache ou non, qu\'elle ait eu des raisons de savoir, qu\'elle ait été informée ou qu\'elle ait été informée d\'une manière ou d\'une autre d\'un tel usage), dans chaque cas en ce qui concerne l\'application. De plus, eCampus LLC, au nom de son propre chef et de tous ses fournisseurs de contenu, agents et autres, renonce expressément à toute garantie de titre et/ou d\'absence de contrefaçon en ce qui concerne l\'application.',
        'limitationOfLiability':'LIMITATION DE LA RESPONSABILITÉ',
        'indemnification':'INDEMNISATION',
        'youAgreeToIndemnifyECampusLLCAndAllOfItsContent':'Vous acceptez d\'indemniser eCampus LLC et tous ses fournisseurs de contenu, agents et autres, dans chaque cas de toutes les réclamations, poursuites, responsabilités, dépenses (y compris, mais sans s\'y limiter, les honoraires d\'avocat), et/ou dommages résultant de réclamations basées sur l\'utilisation de l\'application, y compris, mais sans s\'y limiter, toute réclamation pour calomnie, diffamation, violation des droits légaux d\'autrui (y compris les droits à la vie privée ou à la publicité), perte de service par d\'autres abonnés et/ou violation de la propriété intellectuelle ou d\'autres droits.',
        'links':'LIENS',
        'theAppMayContainLinksToWebsites':'L\'application peut contenir des liens vers des sites Web exploités par des parties autres que eCampus LLC. Ces liens sont fournis à titre de référence seulement. eCampus LLC ne contrôle pas ces sites Web et n\'est pas responsable de leur contenu. eCampus LLC ne fait aucune représentation ou garantie concernant tout autre site Web ou le contenu ou le matériel sur ces sites Web. L\'inclusion dans l\'application de liens vers d\'autres sites Web ne signifie pas que la société eCampus LLC approuve le contenu de ces sites ou qu\'elle est associée à leurs exploitants. Toute préoccupation concernant un tel service ou une telle ressource, ou un lien vers ceux-ci, doit être adressée au service ou à la ressource en question.',
        'governingLaw':'DROIT APPLICABLE',
        'thisAgreementAndItsPerformance':'Le présent accord et son exécution sont régis par les lois du Ghana, sans tenir compte de ses dispositions en matière de conflit de lois. Vous consentez et vous soumettez à la juridiction exclusive des tribunaux du Ghana, pour toutes les questions et controverses découlant de votre utilisation de cette application et de cet accord.',
        'noWaiver':'PAS DE RENONCIATION',
        'theFailureOfEitherPartyToInsistUpon':'Le manquement de l\'une ou l\'autre des parties à insister sur la stricte exécution par l\'autre partie de toute disposition des présentes Conditions générales ne doit pas être interprété comme une renonciation à toute disposition ou droit. Ni le cours de la conduite entre vous et eCampus LLC ni les pratiques commerciales ne modifieront aucune disposition des présentes Conditions générales.',
        'modification':'MODIFICATION',
        'eCampusLLCMayAtAnytimeModify':'eCampus LLC peut à tout moment modifier ces termes et conditions et votre utilisation continue de cette application sera conditionnée par les termes et conditions en vigueur au moment de votre utilisation.',
        'additionalTerms':'CONDITIONS SUPPLÉMENTAIRES',
        'additionalTermsAndConditions':'Des conditions supplémentaires peuvent s\'appliquer aux achats de biens et de services et à d\'autres utilisations de certaines parties de l\'application, et vous acceptez de vous conformer à ces autres conditions.',
        'severability':'SÉVERABILITÉ',
        'theseTermsAndConditionsShall':'Les présentes conditions générales sont réputées divisibles. Si une disposition est jugée inapplicable ou invalide, cette disposition sera néanmoins appliquée dans toute la mesure permise par la loi applicable, et cette décision n\'affectera pas la validité et l\'applicabilité des autres dispositions.',
        'entireAgreement':'INTÉGRALITÉ DE L\'ACCORD',
        'theseTermsAndConditions':'Les présentes conditions générales, ainsi que celles qui sont intégrées aux présentes ou auxquelles il est fait référence, constituent l\'intégralité de l\'accord entre nous concernant l\'objet des présentes, et remplacent tout accord ou arrangement antérieur (oral ou écrit) concernant l\'objet des présentes, et ne peuvent être amendées ou modifiées que par écrit ou en rendant ces amendements ou modifications disponibles sur cette App.',
        'eCampusAppEndUserLicenseAgreement':'Accord de licence de l\'utilisateur final de l\'application eCampus',
        'thisEndUserLicenseAgreement':'Le présent Contrat de licence d\'utilisateur final (" Contrat ") est conclu entre vous et eCampus et régit l\'utilisation de cette application mise à disposition par l\'App Store. En installant l\'application eCampus, vous acceptez d\'être lié par le présent contrat et comprenez qu\'il n\'y a aucune tolérance pour le contenu répréhensible. Si vous n\'êtes pas d\'accord avec les termes et conditions de cet Accord, vous n\'êtes pas autorisé à utiliser l\'Application eCampus.',
        'inOrderToEnsureECampusProvides':'Afin de s\'assurer qu\'eCampus offre la meilleure expérience possible à chacun, nous appliquons une politique de tolérance zéro pour les contenus répréhensibles. Si vous voyez un contenu inapproprié, veuillez utiliser la fonction "Signaler comme offensant" qui se trouve sous chaque message.',
        'partiesThisAgreementIsBetween':'Parties Le présent Accord est conclu entre vous et eCampus uniquement. Nonobstant ce qui précède, vous reconnaissez qu\'App store et ses filiales sont des tiers bénéficiaires du présent Accord et qu\'App store a le droit d\'appliquer le présent Accord à votre encontre. eCampus, et non App store, est seul responsable de l\'Application eCampus et de son contenu.',
        'privacyECampusMayCollect':'Confidentialité eCampus peut collecter et utiliser des informations sur votre utilisation de l\'Application eCampus, y compris certains types d\'informations provenant de et concernant votre appareil. eCampus peut utiliser ces informations, tant qu\'elles sont sous une forme qui ne vous identifie pas personnellement, pour mesurer l\'utilisation et la performance de l\'Application eCampus.',
        'limitedLicenseECampusGrants':'Licence limitée eCampus vous accorde une licence limitée, non exclusive, non transférable et révocable pour utiliser l\'App eCampus à des fins personnelles et non commerciales. Vous ne pouvez utiliser l\'Application eCampus que sur des appareils qui vous appartiennent ou que vous contrôlez et comme le permettent les Conditions de service de l\'App Store.',
        'ageRestrictionsByUsingTheECampusApp':'Restrictions d\'âge En utilisant l\'Application eCampus, vous déclarez et garantissez que (a) vous avez 17 ans ou plus et que vous acceptez d\'être lié par le présent Accord ; (b) si vous avez moins de 17 ans, vous avez obtenu le consentement vérifiable d\'un parent ou d\'un tuteur légal ; et (c) votre utilisation de l\'Application eCampus ne viole aucune loi ou réglementation applicable. Votre accès à l\'Application eCampus peut être résilié sans préavis si eCampus estime, à sa seule discrétion, que vous avez moins de 17 ans et que vous n\'avez pas obtenu le consentement vérifiable d\'un parent ou d\'un tuteur légal. Si vous êtes un parent ou un tuteur légal et que vous donnez votre consentement à l\'utilisation de l\'Application eCampus par votre enfant, vous acceptez d\'être lié par le présent Accord en ce qui concerne l\'utilisation de l\'Application eCampus par votre enfant.',
        'objectionableContentPolicyContent':'Politique relative au contenu inadmissible Le contenu ne peut pas être soumis à eCampus, qui modérera tout le contenu et décidera en dernier ressort d\'afficher ou non une soumission dans la mesure où ce contenu inclut, est en conjonction avec ou à côté de tout contenu inadmissible. Le contenu inacceptable comprend, sans s\'y limiter, les éléments suivants (i) les contenus sexuellement explicites ; (ii) les contenus obscènes, diffamatoires, calomnieux, violents et/ou illégaux ou les blasphèmes ; (iii) les contenus qui enfreignent les droits d\'un tiers, y compris les droits d\'auteur, les marques, la vie privée, la publicité ou tout autre droit personnel ou de propriété, ou qui sont trompeurs ou frauduleux ; (iv) un contenu qui promeut l\'utilisation ou la vente de substances illégales ou réglementées, de produits du tabac, de munitions et/ou d\'armes à feu ; et (v) les jeux d\'argent, y compris, sans s\'y limiter, tout casino en ligne, les paris sportifs, le bingo ou le poker.',
        'warrantyECampusDisclaimsAll':'Garantie eCampus décline toute garantie concernant l\'application eCampus dans toute la mesure permise par la loi. Dans la mesure où une garantie existe en vertu de la loi et qu\'elle ne peut être rejetée, eCampus, sera seul responsable de cette garantie.',
        'maintenanceAndSupportECampus':'Entretien et soutien eCampus fournit un entretien ou un soutien minimal, mais dans la mesure où l\'entretien ou le soutien est exigé par la loi applicable, eCampus ne sera pas obligé de fournir un tel entretien ou soutien.',
        'productClaimsECampus':'Réclamations relatives aux produits eCampus est responsable du traitement de toute réclamation de votre part relative à l\'Application eCampus ou à son utilisation, y compris, mais sans s\'y limiter : (i) toute réclamation relative à la responsabilité du produit ; (ii) toute réclamation selon laquelle l\'Application eCampus ne se conforme pas à toute exigence légale ou réglementaire applicable ; et (iii) toute réclamation découlant de la protection des consommateurs ou d\'une législation similaire. Rien dans le présent Accord ne doit être considéré comme une admission que vous puissiez avoir de telles réclamations.',
        'thirdPartyIntellectualProperty':'Réclamations de tierces parties en matière de propriété intellectuelle eCampus ne sera pas obligée de vous indemniser ou de vous défendre à l\'égard de toute réclamation de tierce partie découlant de l\'Application eCampus ou s\'y rapportant. Dans la mesure où eCampus est tenu de fournir une indemnisation en vertu de la loi applicable, eCampus, sera seul responsable de l\'enquête, de la défense, du règlement et de la décharge de toute réclamation selon laquelle l\'Application eCampus ou votre utilisation de celle-ci enfreint tout droit de propriété intellectuelle d\'un tiers. Accepter Refuser Traduit avec www.DeepL.com/Translator (version gratuite)',
        'toGetStartedAsATeacher':'Pour commencer en tant qu\'enseignant sur eCampus, inscrivez-vous avec vos coordonnées.',
        'fullNameEmail':`<p>Nom complet <br/> Courriel <br/> Contact <br/>Nom de la banque et numéro de compte <br/>Domaines d'enseignement et compétences</p>`,
        'selectYourCourse':'Sélectionnez votre cours et organisez votre contenu sous forme de vidéos, d\'animations ou de textes accompagnés d\'images. Créez des quiz pour aider les étudiants à mieux comprendre ce que vous enseignez.',
        'howECampusHelpsYou':'Comment eCampus vous aide',
        'ourSupportTeamIsReadily':'Notre équipe d\'assistance est à votre disposition pour vous aider à améliorer votre offre aux étudiants. Vous pouvez également suivre votre tableau de bord pour comprendre quels sont les cours et les contenus avec lesquels les étudiants interagissent le plus.',
        'byConstantlyCreatingInteractive':'En créant constamment du contenu interactif et en étant disponible pour offrir des éclaircissements supplémentaires aux étudiants, vous êtes mieux classé et vous transformez plus de vies.',
        'weProvideAllTheTools':'Nous vous fournissons tous les outils dont vous avez besoin pour créer un large public.',
        'signUpToBeATeacher':'Inscrivez-vous pour devenir enseignant',
        'planAndCreateYourContent':'Planifier et créer votre contenu',
        'growAFollowing':'Développez votre audience',
        'earnMoney':'Gagner de l\'argent',
        'withEachPurchase':'Avec chaque achat de votre matériel de cours, vous gagnez plus. Rendez votre contenu de cours interactif et amusant et gagnez plus d\'argent.',
        'howECampusPaymentAreMade':'Comment les paiements eCampus sont effectués',
        'eachMonthPayment':'Chaque mois, les paiements sont effectués par l\'intermédiaire de la banque directement à l\'intention des enseignants d\'eCampus.',
        'contactHours':'Heures de contact',
        'impartAndEarn':'Transmettre et gagner',
        'teachStudentsAll':'Enseignez à des étudiants dans toute l\'Afrique avec votre contenu et gagnez de l\'argent.',
        'loginToDashboard':'Connexion au tableau de bord',
        'earnExtraMoney':'Gagner de l\'argent supplémentaire',
        'getPaidEachTime':'Soyez payé chaque fois qu\'un étudiant interagit avec votre cours. Gagnez davantage tout en transformant une vie grâce à vos compétences.',
        'teachToTransform':'Enseigner pour transformer',
        'beTheReasonAStudent':'Soyez la raison pour laquelle un étudiant devient meilleur dans un cours ou une matière en partageant vos connaissances. Transformez de nombreuses vies grâce à votre contenu.',
        'becomeATeamMember':'Devenir membre de l\'équipe',
        'joinOurCommunityOfTeachers':'Rejoignez notre communauté d\'enseignants, devenez meilleur dans l\'enseignement et gagnez plus.',
        'joinTheTeam':'Rejoignez l\'équipe',
        'transformAStudent':'Transformer un étudiant',
        'becomeAnECampusTeacherTodayTeachToTransform':'Devenez un enseignant eCampus aujourd\'hui, Enseigner pour transformer.',
        'improveYourBusinessWithSkilledEmployees':'Améliorez votre entreprise grâce à des employés qualifiés',
        'identifyHiddenTalents':'Identifiez dès aujourd\'hui les talents cachés dans votre entreprise. Grâce à une combinaison d\'expertise en matière de numérique, de données et de contenu, nous avons conçu des cours qui aideront vos employés à réussir et à améliorer leur efficacité générale au travail.',
        'theWorkDoneByECampus':'"Le travail effectué par eCampus est unique et met l\'accent sur l\'éducation, ce qui a été notre priorité cette année."',
        'marketingBusinessDevelopmentManager':`Mme Jennifer Bebli <br/>Responsable du marketing et du développement commercial <br/>Donewell Insurance, Ghana`,
        'recognizeTheStrengthsOfYourEmployees':'Reconnaissez les points forts de vos employés',
        'identifyTheStrengthsAndWeaknesses':'Identifiez les forces et les faiblesses de vos employés. Accompagnez-les pour qu\'ils deviennent meilleurs et améliorent leur efficacité sur le lieu de travail.',
        'content':'Contenu',
        'pricing':'Fixation des prix',
        'requestDemo':'Demander une démo',
        'demo':'Démo',
        'improveTheSkillsOfYourEmployees':'Améliorez les compétences de vos employés.',
        'giveYourEmployeesAnEdgeToSucceed':'Donnez à vos employés un avantage pour réussir.',
        'learnSmart':'Apprendre à être intelligent',
        'cancel':'Annuler',
        'coachYourEmployees':'Entraînez vos employés à devenir meilleurs et à améliorer l\'efficacité du travail sur le lieu de travail.',
        'firstName':'Prénom',
        'lastName':'Nom de famille',
        'companyName':'Nom de la société',
        'jobTitle':'Titre du poste',
        'contact':'Contact',
        'training':'Formation',
        'idLikeToReceiveNews':"J'aimerais recevoir des nouvelles, des informations et des promotions d'eCampus for Business.",
        'bySigningUpYouAgreeToOur':'En vous inscrivant, vous acceptez notre',
        'termsOfUse':'Conditions d\'utilisation',
        'and':'et',
        'weMayUseTheInformation':'Nous pouvons utiliser les informations que vous soumettez pour vous contacter et utiliser des données provenant de tiers pour personnaliser votre expérience.',
        'bePreparedAlways':'Soyez toujours prêt',
        'passAnyTestOrExamYouTake':'Réussir tout test ou examen que vous passez.',
        'takeATrial':'Faire un essai',
        'toGetStartedOnECampusSignUpWithYourDetails':'Pour commencer sur eCampus, inscrivez-vous avec vos coordonnées.',
        'fullName':'Nom complet',
        'moreThanAHundredCourses':'Plus d\'une centaine de cours sont disponibles à des prix abordables pour s\'exercer et se perfectionner. Sélectionnez le ou les cours que vous souhaitez étudier.',
        'reachOutToOur':'Contactez notre équipe d\'assistance si vous avez besoin de précisions sur les cours que vous souhaitez acheter.',
        'learn':'Apprendre',
        'toBecomeBetterAndMeet':'Pour devenir meilleur et atteindre vos objectifs d\'apprentissage, vous devez apprendre. Après avoir acheté un cours, ne vous reposez pas sur vos lauriers, étudiez à partir de toutes les ressources documentaires et questions disponibles.',
        'takePrepTest':'Passez le test de préparation',
        'nowThatYouHaveStudied':'Maintenant que vous avez étudié, passez de nombreux tests de préparation pour comprendre vos forces et vos faiblesses. Devenez meilleur dans vos points faibles et améliorez vos points forts.',
        'repeat':'Répéter',
        'yourUltimateGoal':'Votre objectif ultime est d\'être le meilleur ! Pour y parvenir, tu dois continuer à t\'entraîner. Répétez le cycle complet et devenez un meilleur étudiant. Atteignez vos objectifs d\'apprentissage avec détermination.',
        'trendingNow':'Tendance actuelle',
        'studyingForAnExam':'Étudier pour un examen ne devrait pas être si difficile. Ne vous laissez pas piéger par le monopole et l\'ennui du système éducatif. Étudiez et améliorez-vous à tout moment, tous les jours et partout. Avec eCampus, apprenez sans restrictions.',
        'payForASelectedCourse':'Payer pour un ou plusieurs cours sélectionnés',
        'learnEarnPoints':'Apprendre et gagner des points',
        'takePrepTestEarnPoints':'Passez le test de préparation et gagnez des points',
        'signUpForMore':"S'inscrire pour en savoir plus",
        'areYouReady':'Êtes-vous prêt',
        'forYourUpComingExamination':'pour votre prochain examen ?',
        'checkHere':'Vérifiez ici',
        'getStarted':'Commencez',
        'doWhateverYouWantWhenYouWantTo':'Faites ce que vous voulez quand vous le voulez.',
        'imagineACompany':'Imaginez une entreprise',
        'work':'Travail',
        'company':'Entreprise',
        'careers':'Carrières',
        'weHelpCompaniesAndCompanions':'Nous aidons les entreprises et les compagnons',
        'weCanGiveYourCompany':"Nous pouvons donner à votre entreprise des superpouvoirs pour faire des choses qu'elle n'aurait jamais cru possibles. Laissez-nous ravir vos clients et répondre à vos besoins... grâce à l'analyse des données.",
        'weMakeBananasThatCanDance':'Nous fabriquons des bananes qui savent danser',
        'yesThatsRight':"Oui, c'est vrai, vous pensiez que c'était de l'ordre du rêve, mais même les bananes peuvent être génétiquement modifiées.",
        'checkThemOut':'Check Them Out',
        'whatACompany':'"Ce qu\'une entreprise"',
        'thatIsWhatTheyAllSayAboutUs':"C'est ce qu'ils disent tous de nous",
        'iShouldntHaveGoneWithTheirCompetitor':'"Je n\'aurais pas dû aller chez leur concurrent."',
        'bringingDreamsToLife':'Donner vie aux rêves',
        'investors':'Investisseurs',
        'blog':'Blog',
        'press':'Appuyez sur',
        'linkedIn':'LinkedIn',
        'eCampusIsAWebAndMobile':"eCampus est une plateforme d'enseignement et d'apprentissage en ligne (EdTech) basée sur le web et le mobile qui utilise l'intelligence artificielle pour aider les apprenants à apprendre plus intelligemment, préparer les apprenants à l'emploi et guider les apprenants vers la prospérité. #LearnSmarter #Employability #Prosperity @eCampusLLC",
        'eCampusIsAWeb':"eCampus est une plateforme d'enseignement et d'apprentissage en ligne (EdTech) basée sur le web et le mobile qui utilise l'intelligence artificielle pour aider les apprenants à apprendre plus intelligemment, préparer les apprenants à l'emploi et guider les apprenants vers la prospérité.",
        'learnSmarter':`<p style="margin-left:20px">#<b>LearnSmarter</b> #<b>Employability</b> #<b>Prosperity</b> <b>@eCampusLLC</b></p>`,
        'ourPartners':'Nos partenaires',
        'areYouInterestedInInvestingInTheFutureOfEducation':"Êtes-vous intéressé à investir dans l'avenir de l'éducation ?",
        'kindlySendUsARequest':'Veuillez nous envoyer une demande pour notre pitch deck en utilisant le formulaire ci-dessous. Merci de votre intérêt pour eCampus.',
        'requestADeck':'Demander une terrasse',
        'readMore':'Lire la suite',
        'whatWeHearAboutUs':'Ce que nous entendons sur nous',
        'investInTheFutureOfEducationToday':"Investissez dès aujourd'hui dans l'avenir de l'éducation",
        'ourInvestors':'Nos investisseurs',
        'ourTeam':'Notre équipe',
        'positivelyImpactingSocietyThroughLearning':"Avoir un impact positif sur la société grâce à l'apprentissage",
        'ourBoard':"Notre conseil d'administration",
        'ourAdvisors':'Nos conseillers',
        'eCampusDeck':'Pont eCampus',
        'signUpToBeAStudent':'S\'inscrire pour être un étudiant',
        'levelUp':'Niveau supérieur',
        'startToday':'Commencez aujourd\'hui',
        'subjectAreas':'Domaines d\'activité',
        'useTheApp':'Utiliser l\'application',
        'makeAnImpact':'Avoir un impact',
        'trendingCoursesNow':'Cours en vogue actuellement',
    }
* */